<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Foto intervento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div v-if="sendingPhotos" class="loading_spinner">
                <h5>Salvataggio delle foto in corso</h5>
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div v-else class="page_content">
                <div class="page_title">
                    <h5>Scegli la categoria a cui aggiungere foto</h5>
                </div>

                <div class="save_button_container">
                    <button @click="savePhoto()" class="btn_save_photo">Salva foto</button>
                </div>

                <div class="category_container">
                    <div v-for="categoria in categorieFoto" :key="categoria.rel_interventi_immagini_categoria_id" class="button_container">
                        <button @click="showActionSheetPhotos(categoria.rel_interventi_immagini_categoria_id)">
                            {{ categoria.rel_interventi_immagini_categoria_value }}
                        </button>
                    </div>
                </div>

                <div class="photos_container">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="6" :key="photo" v-for="photo in userPhoto">
                                <ion-img :src="photo.webviewPath" class="img_photo"></ion-img>
                                <div @click="showActionSheet(photo)" class="img_action">
                                    <ion-icon :icon="trash"></ion-icon>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonButton,
    modalController,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonIcon,
    actionSheetController,
    IonSpinner,
} from "@ionic/vue";
import { camera, trash, images, close, arrowBackOutline } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";

import interventiCustomAPI from "@/custom/services/interventi";

import { usePhotoGallery, UserPhoto } from "@/custom/services/usePhotoGallery";

import imageToBase64 from "image-to-base64/browser";

import categorieCustomAPI from "@/custom/services/foto";

export default defineComponent({
    name: "ImagesModal",
    props: {
        intervento_id: {
            type: String,
            required: true,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        //IonBackButton,
        IonGrid,
        IonRow,
        IonCol,
        IonImg,
        IonIcon,
        IonSpinner,
    },
    setup(props, context) {
        //Camera functionality
        const { userPhoto, takePhoto, deletePhoto, pickPhotos } = usePhotoGallery();

        const sendingPhotos = ref(false);
        const categorieFoto = ref([]);

        let selectedPhotoCategory = null;

        let salvataggioCorretto = false;

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            if (salvataggioCorretto) {
                modalController.dismiss(true);
            } else {
                modalController.dismiss(false);
            }
        };

        /**
         * Handle camera and device selection photos
         */
        const showActionSheetPhotos = async (categoryID) => {
            selectedPhotoCategory = categoryID;
            console.log("current photo category: ", selectedPhotoCategory);
            const actionSheet = await actionSheetController.create({
                header: "Scegli modalità di caricamento",
                buttons: [
                    {
                        text: "Fotocamera",
                        icon: camera,
                        handler: () => {
                            takePhoto(selectedPhotoCategory);
                        },
                    },
                    {
                        text: "Galleria",
                        icon: images,
                        handler: () => {
                            pickPhotos(selectedPhotoCategory);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                            selectedPhotoCategory = null;
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * Handle delete single photo
         */
        const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            deletePhoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * Iterates all photos and convert to base64
         */
        const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        const foto = {
                            category: photo.category,
                            name: fileName,
                            data: response,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        };

        /**
         * Handle save selected photos
         * @param selectedhotoCategory id of the selected category, from button click on each category
         */
        async function savePhoto() {
            if (userPhoto.value.length === 0 || selectedPhotoCategory === null) {
                openToast("Non puoi salvare senza aver aggiunto almeno una foto", "toast_danger");
            } else {
                sendingPhotos.value = true; //loading invio foto
                const intervento = props.intervento_id;

                conversioneFoto(userPhoto.value).then((photosB64) => {
                    //console.log(photosB64);
                    interventiCustomAPI
                        .savePhotos(photosB64, intervento)
                        .then((response) => {
                            //console.log(response);
                            //Se ha salvato devo mostrare toast, svuotare photos e chiudere la modale
                            if (response.data.status === 1) {
                                //openToast("Salvataggio foto effettuato", "success");
                                userPhoto.value.length = 0;
                                salvataggioCorretto = true;
                                closeModal(salvataggioCorretto);
                            } else {
                                openToast("Errore durante il salvataggio foto", "toast_danger");
                            }
                        })
                        .catch((error) => {
                            // handle error
                            console.error(error);
                            openToast("Errore durante il salvataggio foto", "toast_danger");
                        })
                        .finally(() => {
                            sendingPhotos.value = false;
                        });
                });
            }
        }

        onMounted(() => {
            //GET CATEGORIE FOTO
            categorieCustomAPI
                .getCategorieFoto()
                .then((response) => {
                    console.log(response);
                    categorieFoto.value = response;
                })
                .catch((error) => {
                    //error.value = true;
                    openToast("Errore durante la richiesta della categorie foto", "toast_danger");
                })
                .finally(() => {
                    //loading.value = false;
                    //console.log("fine");
                });
        });

        return {
            arrowBackOutline,
            camera,
            trash,
            closeModal,
            userPhoto,
            takePhoto,
            showActionSheet,
            savePhoto,
            sendingPhotos,
            pickPhotos,
            showActionSheetPhotos,
            categorieFoto,
        };
    },
});
</script>

<style scoped>
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: -10px;
    right: -8px;
}

.select100 {
    max-width: 100% !important;
}

.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
    width: 100% !important;
}

.alert-wrapper {
    width: 90% !important;
}

/** NUOVI STILI */
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

.page_content {
    width: 100%;
    position: relative;
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.save_button_container {
    margin: 16px 0;
}
.save_button_container button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #0c9020;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.category_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.category_container .button_container {
    width: 100%;
}
.category_container .button_container button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

/* Riportati da ticket*/
.richiesta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .address_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.richiesta .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.richiesta .address_field .title {
    margin-bottom: 4px;
}
.field .title,
.field_allegati .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_allegati .title,
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_allegati .value,
.field_note .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_close,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_answer {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #475569;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.intervento .status {
    font-size: 14px;
}

.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}

.no_interventi_container {
    width: 100%;
    padding: 16px;
}

.no_interventi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

ion-button {
    --color: #ffffff;
}

/**
* Messaggi ticket
*/
.messages {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.messages .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}

.messages > .title {
    font-size: 16px;
    font-weight: 500;
    color: #475569;
}

.messages .messages_container {
    display: flex;
    flex-direction: column;
    margin: 8px;
}

.messages .messages_container .message_content {
    width: 90%;
    margin-bottom: 12px;
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
}

.messages .messages_container .message_content.user_message {
    align-self: end;
    background-color: #086fa3;
    color: #ffffff;
}
.messages .messages_container .message_content.customer_message {
    align-self: start;
    background-color: #f1f5f9;
    color: #475569;
}
.messages .messages_container .message_content .date {
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    margin-top: 8px;
}
.messages .messages_container .message_content.user_message .date {
    color: #ffffff;
}
.messages .messages_container .message_content.customer_message .date {
    color: #475569;
}
</style>
