<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo intervento</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Selezione tecnici -->
                <Transition name="fade-transition">
                    <div v-show="showModalTecnici" class="tecnici_modal">
                        <div class="tecnici_selection">
                            <input type="text" placeholder="Cerca tecnico" v-model="searchQuery" class="search_query_input" />
                            <div v-if="searchedTecnici.length > 0" class="searched_result_list">
                                <div
                                    v-for="tecnico in searchedTecnici"
                                    :key="tecnico.users_id"
                                    @click="setSeletedTecnici(tecnico)"
                                    class="tecnico"
                                    :class="{ 'selected_tecnico': isSelectedTecnico(tecnico) }"
                                >
                                    {{tecnico.users_first_name}} {{tecnico.users_last_name}}
                                </div>
                            </div>
                            <div v-else class="no_result">Nessun risultato</div>
                        </div>
                        <div @click="closeTecniciSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaIntervento()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Cliente
                                </div>
                                <div class="value">
                                    {{ printCustomer() }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetto -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Impianto
                                </div>
                                <div class="value">{{ printProject() }}</div>
                            </div>
                        </ion-item>

                        <!-- Sede cliente -->
                        <!-- <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Sede
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="sede_cliente"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.sede_cliente"
                                    >
                                        <ion-select-option
                                            v-for="sede in sedi_cliente"
                                            :key="sede.customers_shipping_address_id"
                                            :value="sede.customers_shipping_address_id"
                                        >
                                            {{ `${sede.customers_shipping_address_city}, ${sede.customers_shipping_address_street} ` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item> -->

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="data"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2024"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora fine -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ore di pausa -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ore di pausa
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="ore_pausa"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.ore_pausa"
                                    >
                                        <ion-select-option value="5"> 0 </ion-select-option>
                                        <ion-select-option value="1"> 0,5 </ion-select-option>
                                        <ion-select-option value="2"> 1 </ion-select-option>
                                        <ion-select-option value="3"> 1,5 </ion-select-option>
                                        <ion-select-option value="4"> 2 </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ore da fatturare -->
                        <!-- <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Ore da fatturare <small>(lasciare vuoto per calcolo automatico)</small></div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="intervento.ore_fatturabili"> </ion-input>
                                </div>
                            </div>
                        </ion-item> -->

                        <!-- Tipologia -->
                        <!--<ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Tipo
                                </div>
                                <div class="value">
                                    <ion-select name="tipo" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.tipo">
                                        <ion-select-option
                                            v-for="tipo in tipologia_intervento"
                                            :key="tipo.tickets_reports_type_id"
                                            :value="tipo.tickets_reports_type_id"
                                        >
                                            {{ `${tipo.tickets_reports_type_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item> -->

                        <!-- Capo cantiere -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Capo cantiere
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="capo_cantiere" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.capo_cantiere">
                                        <ion-select-option v-for="capo_cantiere in capi_cantiere" :key="capo_cantiere.users_id" :value="capo_cantiere.users_id">
                                            {{ `${capo_cantiere.users_first_name} ${capo_cantiere.users_last_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Tecnici -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title field_title_tecnici">
                                    <span>Tecnici <ion-text color="danger"><strong>*</strong></ion-text></span>
                                    <span @click="openTecniciSelection()" class="open_tecnici_selection">Seleziona</span>
                                </div>
                                <div class="value">
                                    <!-- <ion-select
                                        name="tecnici"
                                        multiple="true"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.tecnici"
                                        @ionChange="setTecnici($event.target.value)"
                                    >
                                        <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico.users_id">
                                            {{ `${tecnico.users_first_name} ${tecnico.users_last_name}` }}
                                        </ion-select-option>
                                    </ion-select> -->
                                    <div v-if="selectedTecnici.length > 0" class="selected_tecnici_container">
                                        <div v-for="tecnico in selectedTecnici" :key="tecnico.users_id" class="single_selected_tecnico">
                                            {{ `${tecnico.users_first_name} ${tecnico.users_last_name}` }}
                                        </div>                                        
                                    </div>
                                    <div v-else class="no_tecnici_selected">Clicca "Seleziona" per scegliere i tecnici</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Conducente -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Conducenti
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="tecnici" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.conducente">
                                        <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico.users_id">
                                            {{ `${tecnico.users_first_name} ${tecnico.users_last_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Automezzi -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Automezzi
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="automezzi" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.automezzo">
                                        <ion-select-option v-for="auto in automezzi" :key="auto.automezzi_id" :value="auto.automezzi_id">
                                            {{ auto.automezzi_targa }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Km percorsi -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Km percorsi
                                </div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="intervento.km_percorsi" placeholder="12"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Tempo di viaggio -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Tempo di viaggio (min)
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="intervento.tempo_viaggio" placeholder="35"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Stato lavori -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Stato lavori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="stato_lavori"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.stato_lavori"
                                    >
                                        <ion-select-option
                                            v-for="stato in stati_lavoro"
                                            :key="stato.interventi_stato_lavori_id"
                                            :value="stato.interventi_stato_lavori_id"
                                        >
                                            {{ `${stato.interventi_stato_lavori_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Descrizione
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="intervento.descrizione"
                                        placeholder="Descrizione lavoro svolto"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Materiali -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Materiali</div>
                                <div class="value">
                                    <ion-textarea type="text" rows="4" v-model="intervento.materiali_utilizzati" placeholder="Elenco dei materiali utilizzati">
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Note -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Note</div>
                                <div class="value">
                                    <ion-textarea type="text" rows="4" v-model="intervento.note" placeholder="Eventuali note intervento"> </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Nota spesa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field field_scontrino">
                                <div class="field_title" position="floating">Crea nota spesa</div>
                                <ion-checkbox v-model="intervento.crea_nota_spesa" @update:modelValue="intervento.crea_nota_spesa = $event"></ion-checkbox>
                            </div>
                        </ion-item>

                        <!-- Tecnici nota spesa -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">Tecnici nota spesa</div>
                                <div class="value">
                                    <ion-select name="tecnici" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.tecnici_nota_spesa">
                                        <ion-select-option
                                            v-for="tecnico in filteredDipendenti"
                                            :key="tecnico.dipendenti_user_id"
                                            :value="tecnico.dipendenti_user_id"
                                        >
                                            {{ `${tecnico.dipendenti_nome} ${tecnico.dipendenti_cognome}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Foto scontrino -->
                        <ion-item lines="none" class="ion-no-padding" v-if="userPhoto.length != 0">
                            <div class="field">
                                <div class="field_title" position="floating">Foto scontrino</div>
                                <div class="foto_container">
                                    <div v-for="(foto, index) in userPhoto" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <img :src="foto.webviewPath" />
                                        </ion-thumbnail>
                                        <div @click="deleteSinglePhoto(foto)" class="remove_photo">Rimuovi</div>
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="button" class="btn_allega_foto" @click="showActionSheetPhotos()">
                                Carica foto scontrino
                            </button>
                            <button type="submit" class="btn_crea_intervento" :disabled="sendingData">
                                Salva
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonIcon,
    IonCheckbox,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline, close, camera, images } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import { openToast } from "@/services/toast";

import { useScontrinoPhoto, UserPhoto } from "@/custom/services/useScontrinoPhoto";

import imageToBase64 from "image-to-base64/browser";

import apiClienti from "@/services/clienti";
import apiInterventi from "@/services/interventi";
import apiTecnici from "@/services/tecnici";
import apiAutomezzi from "@/services/automezzi";
import apiInterventiCustom from "@/custom/services/interventi";
import apiDipendenti from "@/custom/services/dipendenti";
import apiUserCustom from "@/custom/services/users";

import moment from "moment";

import ImagesModal from "@/custom/interventi/ImagesModal";
import ModalFirmaIntervento from "@/custom/interventi/ModalFirmaIntervento";
import ScontrinoModal from "@/custom/interventi/ScontrinoModal";

export default defineComponent({
    name: "ModalNuovoInterventoFromTicket",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonThumbnail,
        IonIcon,
        IonCheckbox,
    },
    setup(props, context) {
        console.log(props.data.value);
        const router = useRouter();

        const start_date = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(end_hour.getHours() + 2);

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const intervento = reactive({
            cliente: props.data.value.projects_customer_id ? props.data.value.projects_customer_id : null,
            sede_cliente: "",
            progetto: props.data.value.projects_id ? props.data.value.projects_id : null,
            data: start_date.toISOString(),
            ora_inizio: start_hour.toISOString(),
            ora_fine: end_hour.toISOString(),
            tempo_viaggio: null,
            ore_fatturabili: "",
            tipo: "",
            stato_lavori: "",
            tecnici: [],
            automezzo: [],
            conducente: [],
            km_percorsi: "",
            descrizione: "",
            materiali_utilizzati: "",
            note: "",
            allegati: [],
            firma_tecnico: "",
            firma_cliente: "",
            foto_scontrino: [],
            crea_nota_spesa: false,
            tecnici_nota_spesa: [],
            capo_cantiere: null,
            ore_pausa: null,
        });

        const successResponse = ref(false);
        const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

        // Qui ci arrivo da dati diversi devo prendermi dinamicamente il value
        const impianto_id = props.data.value.projects_id ? props.data.value.projects_id : props.data.value.appuntamenti_impianto;
        //console.log(impianto_id);

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Gestione foto scontrino
         */
        const { userPhoto, takePhoto, deleteSinglePhoto } = useScontrinoPhoto();
        let photoToSave = null;
        /**
         * ! Handle camera and device selection photos
         */
        const showActionSheetPhotos = async () => {
            const actionSheet = await actionSheetController.create({
                header: "Scegli modalità di caricamento",
                buttons: [
                    {
                        text: "Fotocamera",
                        icon: camera,
                        handler: () => {
                            //deleteSinglePhoto(); //cancella eventuale foto caricata precedentemente
                            takePhoto();
                        },
                    },
                    /* {
                        text: "Galleria",
                        icon: images,
                        handler: () => {
                            //deleteSinglePhoto(); //cancella eventuale foto caricata precedentemente
                            pickPhotos();
                        },
                    }, */
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * !  Iterates all photos, convert to base64 and return.
         */
        const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        //fileName = fileName.substring(0, fileName.length - 5);
                        //console.log(str);
                        const foto = {
                            name: fileName,
                            data: response,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        };

        /* const fotoScontrino = ref([]);
        async function openScontrinoModal() {
            const modal = await modalController.create({
                component: ScontrinoModal, //Component to render inside ionic modal
                componentProps: {
                    images: fotoScontrino.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            intervento.foto_scontrino.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        } */

        /**
         * ! Gestione foto intervento
         */
        const fotoIntervento = ref([]);

        async function openModalPhotos() {
            const modal = await modalController.create({
                component: ImagesModal, //Component to render inside ionic modal
                componentProps: {
                    images: fotoIntervento.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            intervento.allegati.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Remove photo from the fotoInterventi reference data array
         */
        function deletePhoto(photo) {
            intervento.allegati = intervento.allegati.filter((foto) => foto.name !== photo);
        }

        /**
         * ! Open modal to sign
         */
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data) {
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            intervento.firma_tecnico = detail.data.firma;
                        } else {
                            intervento.firma_cliente = detail.data.firma;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Return customer information
         */
        function printCustomer() {
            if (props.data.value.customers_company) {
                return props.data.value.customers_company;
            } else {
                return `${props.data.value.customers_name} ${props.data.value.customers_last_name}`;
            }
        }
        /**
         * ! Return Project name
         */
        function printProject() {
            if (props.data.value.projects_codice && props.data.value.projects_impianto_indirizzo) {
                return `${props.data.value.projects_codice} - ${props.data.value.projects_impianto_indirizzo}`;
            } else if (props.data.value.projects_codice && !props.data.value.projects_impianto_indirizzo) {
                return props.data.value.projects_codice;
            } else {
                return "-";
            }
        }

        /* const sedi_cliente = ref([]);
        async function loadSediCliente() {
            if (props.data.value.tickets_customer_id) {
                try {
                    const res = await apiClienti.getSediCliente(props.data.value.tickets_customer_id);
                    sedi_cliente.value = res.data;
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante la richiesta delle sedi del ciente", "toast_danger");
                }
            }
        } */

        /* const tipologia_intervento = ref([]);
        async function loadTipologieIntervento() {
            try {
                const res = await apiInterventi.getTipologieIntervento();
                tipologia_intervento.value = res;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle tipologie di intervento effettuabili", "toast_danger");
            }
        } */

        const stati_lavoro = ref([]);
        async function loadStatiLavoro() {
            try {
                const res = await apiInterventiCustom.getStatoLavori();
                stati_lavoro.value = res;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli stati dei lavori", "toast_danger");
            }
        }

        function compareWith(f1, f2) {
            /* console.log(f1, f2);
            console.log("---compareWith---", f2); */
            if (Array.isArray(f2)) {
                if (!f1 || !f1.users_id) {
                    return false;
                }
                return f2.find((val) => val && val.users_id === f1.users_id);
            }
            return f1 && f2 ? f1.users_id === f2.users_id : f1 === f2;
        }

        const tecnici = ref([]);
        const tecnicoLoggato = ref({});
        async function loadTecnici() {
            try {
                const res = await apiTecnici.getTecnici();
                tecnici.value = res;
                /* tecnicoLoggato.value = tecnici.value.find((el) => el.users_id === userID);
                if (tecnicoLoggato.value) {
                    intervento.tecnici.push(tecnicoLoggato.value);
                    //compareWith(tecnicoLoggato.value, tecnicoLoggato.value);
                } */
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei tecnici", "toast_danger");
            }
        }

        const automezzi = ref([]);
        async function loadAutomezzi() {
            try {
                const res = await apiAutomezzi.getAutomezzi();
                automezzi.value = res;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli automezzi", "toast_danger");
            }
        }

        const dipendenti = ref([]);
        const filteredDipendenti = ref([]);
        async function loadDipendenti() {
            try {
                const res = await apiDipendenti.getDipendenti();
                if (res.status === 200 && res.data.data.length != 0) {
                    // Qui ho tutti i dipendenti ma poi devo filtrarli in base a quelli che seleziono come tecnici quindi mi serve un altro
                    // array di appoggio dove mostro i filtrati, qui tengo sempre tutti quanti
                    dipendenti.value = res.data.data;
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei dipendenti", "toast_danger");
            }
        }

        /**
         * ! Gestione capi cantiere da indicare nell'intervento
         */
        const capi_cantiere = ref([]);
        async function loadCapiCantiere() {
            try {
                const res = await apiUserCustom.getCapiCantiere();
                if (res.status === 200 && res.data.status === 0) {
                    capi_cantiere.value = res.data.data;
                } else {
                    capi_cantiere.value = [];
                    openToast("Si è verificato un errore durante la richiesta dei capi cantiere", "toast_danger");
                    return;
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei dipendenti", "toast_danger");
            }
        }

        /**
         * ! In base ai tecnici selezionati ( array con id dell'utente) devo mostrare
         * ! solo questi dipendenti nella select dei tecnici nota spesa (dipendenti)
         * */
/*         function setTecnici(event) {
            console.log("setTecnici", event);
            const selectedTecnici = event;
            // Inserisco in filteredDipendenti solo quelli che hanno il valore selezionato = dipendenti_users_id
            // Filtra gli elementi dell'array dei dipendenti in base agli ID dei tecnici selezionati
            const dipendentiFiltrati = dipendenti.value.filter((dipendente) => {
                return selectedTecnici.includes(dipendente.dipendenti_user_id);
            });
            console.log(selectedTecnici);

            // Ordino per cognome
            dipendentiFiltrati.sort((a, b) => {
                const lastNameA = a.dipendenti_cognome.toLowerCase();
                const lastNameB = b.dipendenti_cognome.toLowerCase();

                if (lastNameA < lastNameB) {
                    return -1;
                } else if (lastNameA > lastNameB) {
                    return 1;
                } else {
                    return 0;
                }
            });

            // Popolo solo dipendenti filtrati in base a quelli selezionati
            filteredDipendenti.value = dipendentiFiltrati;
        } */


        /**
         * 
         * ! GESTIONE SELEZIONE TECNICI
         * 
         */
        const showModalTecnici = ref(false);
        function openTecniciSelection() {
            showModalTecnici.value = true;
        }

        function closeTecniciSelection() {
            showModalTecnici.value = false;
        }

        const searchQuery = ref("");
        const normalizeStringProduct = (str) => str.toLowerCase().replace(/ /g, "");

        const searchedTecnici = computed(() => {
            const term = normalizeStringProduct(searchQuery.value);

            return tecnici.value.filter((tecnico) => {
                const fullName = `${tecnico.users_first_name} ${tecnico.users_last_name}`;
                return normalizeStringProduct(fullName).includes(term);
            });
        });

        const selectedTecnici = ref([]);
        // Filtra i dipendenti sulla base dei tecnici selezionati prendendo i dipendenti che hanno users_id uguale a quelli selezionati
        function setTecnici() {
            filteredDipendenti.value = dipendenti.value.filter((dipendente) => {
                return selectedTecnici.value.some((tecnico) => tecnico.users_id === dipendente.dipendenti_user_id);
            });
        }

        function setSeletedTecnici(tecnico) {
            if (tecnico) {
                const index = selectedTecnici.value.findIndex(t => t.users_id === tecnico.users_id);

                if (index === -1) {
                    // Aggiungo tecnico
                    searchQuery.value = "";
                    selectedTecnici.value.push(tecnico);
                } else {
                    // Rimuovo tecnico
                    selectedTecnici.value.splice(index, 1);
                }

                // Imposta i tecnici nel rapportino
                intervento.tecnici = selectedTecnici.value.map(t => t.users_id);

                // Filtra dipendenti per nota spese sulla base dei tecnici selezionati
                setTecnici();
            }
        }

        const isSelectedTecnico = (tecnico) => {
            return selectedTecnici.value.some(t => t.users_id === tecnico.users_id);
        };



        /**
         * ! Create new intervento
         */
        const sendingData = ref(false);

        async function creaIntervento() {
            const tecnico_id = userID; //set technician as logged user id

            //Controllo che la pausa sia selezioanto
            if (!intervento.ore_pausa) {
                openToast("La pausa effettuata è obbligatoria", "toast_danger");
                return;
            }
            // Capo cantiere
            if (!intervento.capo_cantiere) {
                openToast("La selezione del capo cantiere è obbligatoria", "toast_danger");
                return;
            }
            //Controllo che ci sia almeno un tecnico selezioanto
            if (intervento.tecnici.length === 0) {
                openToast("Non puoi creare un intervento senza selezionare i tecnici", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra i tecnici selezionati
            const isUSerSelected = intervento.tecnici.includes(userID);
            if (!isUSerSelected && intervento.tecnici.length != 0) {
                openToast("Non puoi creare un intervento senza essere tra i tecnici selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(intervento.ora_inizio);
            const ora_fine = new Date(intervento.ora_fine);
            if (ora_inizio > ora_fine) {
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }
            if (intervento.conducente.length === 0) {
                openToast("Non puoi creare un intervento senza selezionare almeno un conducente", "toast_danger");
                return;
            }
            if (intervento.automezzo.length === 0) {
                openToast("Non puoi creare un intervento senza selezionare almeno un automezzo", "toast_danger");
                return;
            }

            if (!intervento.tempo_viaggio) {
                openToast("Il tempo di viaggio è obbligatorio", "toast_danger");
                return;
            }

            //Controllo che stato lavori sia selezioanto
            if (!intervento.stato_lavori) {
                openToast("Lo stato dei lavori è obbligatorio", "toast_danger");
                return;
            }

            if (intervento.crea_nota_spesa && intervento.tecnici_nota_spesa.length === 0) {
                openToast("Devi selezionare i tecnici per creare la nota spesa in automatico", "toast_danger");
                return;
            }

            //Se ho scelto di creare nota spesa devo per forza aver caricato almeno una foto
            //if (intervento.crea_nota_spesa && intervento.foto_scontrino.length === 0) {
            if (intervento.crea_nota_spesa && userPhoto.value.length === 0) {
                openToast("Devi caricare la foto dello scontrino per creare la nota spesa in automatico", "toast_danger");
                return;
            }

            const data_inizio_formatted = moment(intervento.data).format("YYYY-MM-DD");
            const ora_inizio_formatted = moment(intervento.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(intervento.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("interventi_operatore", tecnico_id); //logged user
            data.append("interventi_cliente", props.data.value.projects_customer_id);
            data.append("interventi_impianto", impianto_id);
            data.append("interventi_data", data_inizio_formatted);
            data.append("interventi_ora_inizio", ora_inizio_formatted);
            data.append("interventi_ora_fine", ora_fine_formatted);
            data.append("interventi_ore_pausa", intervento.ore_pausa);
            data.append("interventi_tempo_viaggio", intervento.tempo_viaggio);
            data.append("interventi_km_percorsi", intervento.km_percorsi);
            //data.append("tickets_reports_billable_hours", intervento.ore_fatturabili);
            //data.append("tickets_reports_type", intervento.tipo);
            data.append("interventi_stato_lavori", intervento.stato_lavori);

            intervento.tecnici.forEach((tecnico) => {
                data.append("interventi_tecnici[]", tecnico);
            });
            intervento.conducente.forEach((conducente) => {
                data.append("interventi_conducente[]", conducente);
            });
            intervento.automezzo.forEach((automezzo) => {
                data.append("interventi_automezzo[]", automezzo);
            });

            data.append("interventi_descrizione", intervento.descrizione);
            data.append("interventi_materiale_utilizzato", intervento.materiali_utilizzati);
            data.append("interventi_note", intervento.note);

            //Gestione campi nuovi per creazione nota spesa
            data.append("interventi_crea_nota_spesa", intervento.crea_nota_spesa);

            intervento.tecnici_nota_spesa.forEach((tecnico) => {
                data.append("interventi_tecnici_nota_spesa[]", tecnico);
            });

            // Capo cantiere
            if (intervento.capo_cantiere) {
                data.append("interventi_capo_cantiere", intervento.capo_cantiere);
            }

            /**
             * ! Se ho caricato foto la converto, passo il dato a FormData e continuo con il salvataggio
             */
            if (userPhoto.value.length) {
                const convertedPhoto = await conversioneFoto(userPhoto.value);
                photoToSave = convertedPhoto[0].data;
                data.append("interventi_scontrino", photoToSave);
            }

            // Display the key/value pairs
            /* for (const pair of data.entries()) {
                console.log(pair[0] + ", " + pair[1]);
            }
            return; */

            sendingData.value = true;

            try {
                const response = await apiInterventiCustom.saveIntervento(data);
                if (response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la creazione dell'intervento", "toast_danger");
            } finally {
                sendingData.value = false;
            }
        }

        onMounted(() => {
            //loadSediCliente();
            //loadTipologieIntervento();
            loadStatiLavoro();
            loadTecnici();
            loadAutomezzi();
            loadDipendenti();
            loadCapiCantiere();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaIntervento,
            intervento,
            //sedi_cliente,
            //tipologia_intervento,
            stati_lavoro,
            tecnici,
            automezzi,
            printCustomer,
            printProject,
            //Allegati (foto)
            openModalPhotos,
            deletePhoto,
            //Firme
            openSignature,
            //Gestione foto scontrino + creazione nota spesa
            //openScontrinoModal,
            dipendenti,
            //Caricamento foto singola scontrino
            showActionSheetPhotos,
            userPhoto,
            deleteSinglePhoto,
            sendingData,
            // In tecnici nota spesa inserisco solo i dipendenti uguali ai tecnici che ho selezionato sopra
            setTecnici,
            filteredDipendenti,
            // Capo cantiere
            capi_cantiere,
            // Ricerca e selezione tecnici
            showModalTecnici,
            openTecniciSelection,
            closeTecniciSelection,
            setSeletedTecnici,
            isSelectedTecnico,
            searchQuery,
            searchedTecnici,
            selectedTecnici
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}
.field_title_tecnici {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    background-color: rgb(21, 118, 56);
}
ion-button {
    --color: #ffffff;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

/** Allegati */
.action {
    margin-top: 16px;
}
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
    margin-bottom: 24px;
}
.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    margin-bottom: 16px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

.field_scontrino {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.field_scontrino .field_title {
    margin-right: 16px;
}

/**
* Modal tecnici
*/
.field_title.product_selection_trigger {
    display: flex;
    justify-content: space-between;
}

.field_title.product_selection_trigger .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.tecnici_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_query_input {
    width: 100%;
    background: transparent;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_query_input::placeholder {
    color: #64748b;
}
.search_query_input:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}

.tecnici_selection {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.searched_result_list {
    overflow-y: scroll;
}
.tecnici_selection .no_result {
    text-align: center;
    font-weight: 500;
    color: #64748b;

}
.searched_result_list .tecnico {
    padding: 4px 8px;
    margin-bottom: 8px;
}
.selected_tecnico {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.no_data {
    font-size: 14px;
}

.open_tecnici_selection {
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;
    background: #086fa3;
}
.selected_tecnici_container .single_selected_tecnico{
    font-size: 14px;
    margin-bottom: 4px;
}
.no_tecnici_selected {
    font-size: 14px;
    color: #000000;
    margin-bottom: 4px;
}
</style>
