import axios from "axios";

export default {
    async getCustomers() {
        const data = new FormData();
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/customers", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response.data;
    },
};
