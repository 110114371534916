<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio impianto</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="richiesta">
                    <div class="field field_title">
                        <div class="title project_title">Impianto #{{ progetto.projects_codice }}</div>
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ riferimentoCliente(progetto) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Tipologia</div>
                        <div class="value">
                            {{ progetto.projects_type_value || " - " }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Città</div>
                        <div class="value">
                            {{ progetto.comuni_label || " - " }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Indirizzo</div>
                        <a
                            class="value mobile_link"
                            :href="`https://maps.google.com/?q=${progetto.comuni_label} ${progetto.projects_impianto_indirizzo} ${progetto.customers_zip_code}`"
                        >
                            {{ progetto.projects_impianto_indirizzo }}
                        </a>
                    </div>
                    <div class="field">
                        <div class="title">Capo commessa</div>
                        <div class="value">
                            {{ showCapoCommessa(progetto) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data contratto</div>
                        <div class="value">
                            {{ dateFormat(progetto.projects_data_contratto) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Telefono</div>
                        <div class="value">
                            <span v-if="progetto.customers_telefono || progetto.customers_phone">
                                <a :href="`tel:${progetto.customers_telefono || progetto.customers_phone}`" class="mobile_link">{{ progetto.customers_telefono || progetto.customers_phone }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato</div>
                        <div class="value">
                            <div class="stato_progetto">
                                <span class="badge" :class="statoProgetto(progetto.projects_status_id)">
                                    {{ progetto.projects_status_value }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="nuovo_intervento_container">
                    <button class="btn_nuovo_intervento" @click="openNuovoIntervento()">
                        Crea intervento
                    </button>
                </div>

                <div class="data_switcher">
                    <div class="type" :class="{ active: showLibretto }" @click="setActiveData('libretto')">Libretto</div>
                    <div class="type" :class="{ active: showInstallazioni }" @click="setActiveData('installazioni')">Installazioni</div>
                    <div class="type" :class="{ active: showLavorazioni }" @click="setActiveData('lavorazioni')">Lavorazioni</div>
                    <div class="type" :class="{ active: showDocumenti }" @click="setActiveData('documenti')">Documenti</div>
                    <div class="type" :class="{ active: showMateriali }" @click="setActiveData('materiali')">Materiali</div>
                    <div class="type" :class="{ active: showImmagini }" @click="setActiveData('immagini')">Immagini</div>
                    <!-- <div class="type" :class="{ active: showInterventi }" @click="setActiveData('interventi')">Interventi</div>
                    <div class="type" :class="{ active: showTickets }" @click="setActiveData('ticket')">Ticket</div> -->
                </div>

                <div v-if="showLibretto" class="interventi_container">
                    <div v-if="loadingInterventi" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="libretto.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    Il libretto impianto è vuoto
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="intervento in libretto" :key="intervento.interventi_id" class="libretto" @click="openInterventoDetail(intervento)">
                            <div class="date">
                                {{ dateFormat(intervento.interventi_data) }}
                            </div>
                            <div class="libretto_text">
                                {{ intervento.interventi_descrizione }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showInstallazioni" class="interventi_container">
                    <div>
                        <div v-if="installazioni.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    Non sono state effettuate installazioni
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            v-for="intervento in installazioni"
                            :key="intervento.interventi_id"
                            class="libretto"
                            @click="openInterventoDetail(intervento)"
                        >
                            <div class="date">
                                {{ dateFormat(intervento.interventi_data) }}, {{ intervento.interventi_ora_inizio + " - " + intervento.interventi_ora_fine }}
                            </div>
                            <div class="libretto_text">
                                {{ intervento.interventi_descrizione }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showLavorazioni" class="interventi_container">
                    <div>
                        <div v-if="lavorazioni.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    L'impianto non ha lavorazioni associate
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="(lavorazione, index) in lavorazioni" :key="index" class="lavorazioni">
                            <div class="nome">
                                {{ lavorazione.lavorazioni_nome }}
                            </div>
                            <div class="toggler">
                                <ion-toggle
                                    :checked="lavorazione.commesse_lavorazioni_completato == 1 ? true : false"
                                    :color="lavorazione.commesse_lavorazioni_completato == 1 ? 'primary' : ''"
                                    @ionChange="handleToggleLavorazione(lavorazione.commesse_lavorazioni_id, lavorazione.commesse_lavorazioni_completato)"
                                >
                                </ion-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showDocumenti" class="interventi_container">
                    <div>
                        <div v-if="documenti.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    L'impianto non ha documenti caricati
                                </div>
                            </div>
                        </div>
                        <div v-else class="documenti">
                            <div v-for="(documento, index) in documenti" :key="index" class="documento">
                                <div class="nome">
                                    <span v-if="!documento.documenti_progetto_file">
                                        {{ documento.documenti_progetto_titolo }}
                                    </span>
                                    <a v-else :href="linkDocumento(documento)" target="_blank" class="link_documento">
                                        {{ documento.documenti_progetto_titolo }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showMateriali" class="interventi_container">
                    <div>
                        <div v-if="materiali.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    L'impianto non ha materiali caricati
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="materiale in materiali" :key="materiale.projects_billing_items_id" class="libretto">
                            <div class="date">{{ materiale.items_marca_value }} - {{ materiale.items_name }}</div>
                            <div class="libretto_text">
                                Quantità:
                                {{ materiale.projects_billing_items_quantity }} - Stato:
                                {{ materiale.projects_billing_items_stato_value }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showImmagini" class="interventi_container">
                    <div>
                        <div v-if="immagini.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    Nessuna foto da visualizzare
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="(category, index) in immagini" :key="index" class="libretto">
                            <div class="date">{{ category.category }}</div>
                            <div class="libretto_text">
                                <div v-if="category.images.length != 0" class="gallery_container">
                                    <div v-for="(img, index) in category.images" :key="index">
                                        <ion-thumbnail @click="setSelectedImage(img)">
                                            <img :src="`https://crm.omniaenergy.eu/uploads/${img.interventi_immagini_file}`" />
                                        </ion-thumbnail>
                                    </div>
                                </div>
                                <div v-else class="no_photo_uploaded">
                                    Nessuna foto caricata
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Fake modal for selected image -->
                <div class="fake_modal" v-if="showModalImmagini">
                    <div class="image_container">
                        <div class="close_modal" @click="closeModalImmagini()">
                            <ion-icon :icon="closeOutline"></ion-icon>
                        </div>

                        <img :src="`https://crm.omniaenergy.eu/uploads/${selectedImage}`" />
                    </div>
                </div>

                <!-- <div v-if="showInterventi" class="interventi_container">
                    <div v-if="loadingInterventi" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="interventi.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    Nessun intervento effettuato
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            v-for="intervento in interventi"
                            :key="intervento.tickets_reports_id"
                            class="intervento"
                            @click="openInterventoDetail(intervento)"
                        >
                            <div class="date">
                                {{ dateFormat(intervento.tickets_reports_date) }},
                                {{ intervento.tickets_reports_start_time + " - " + intervento.tickets_reports_end_time }}
                            </div>
                            <div class="badge" :class="statusIntervento(intervento.tickets_reports_stato_lavori)">
                                {{ intervento.tickets_reports_stato_lavori_value }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="showTickets" class="tickets_container">
                    <div v-if="loadingTickets" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="tickets.length == 0">
                            <div class="no_tickets_container">
                                <div class="no_tickets">
                                    Nessun ticket da visualizzare
                                </div>
                            </div>
                        </div>
                        <div v-else v-for="ticket in tickets" :key="ticket.tickets_reports_id" class="ticket" @click="openTicketDetail(ticket)">
                            <div class="info">
                                <div class="title">#{{ ticket.tickets_id }} - {{ ticket.tickets_subject }}</div>
                                <div class="date">
                                    {{ dateFormatWithMinutes(ticket.tickets_creation_date) }}
                                </div>
                            </div>
                            <div class="status">
                                <div class="badge" :class="statoTicket(ticket.tickets_status)">
                                    {{ ticket.tickets_status_value }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
    IonToggle,
    IonThumbnail,
    onIonViewWillEnter,
    modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline, closeOutline } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";

import interventiAPI from "@/services/interventi";
import ticketAPI from "@/services/tickets";
import interventiCustomAPI from "@/custom/services/interventi";

//import ModalNuovoInterventoFromTicket from "@/custom/interventi/ModalNuovoInterventoFromTicket";
import ModalNuovoInterventoFromImpianto from "@/custom/interventi/ModalNuovoInterventoFromImpianto";
//import InterventoDetail from "@/components/InterventoDetail";
import TicketDetail from "@/components/TicketDetail";

//NEW CUSTOM COMPONENT
import InterventoDetail from "@/custom/interventi/InterventoDetail";

export default defineComponent({
    name: "ProjectDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonSpinner,
        IonToggle,
        IonThumbnail,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();

        //console.log(props.data);
        const progetto = ref([]);
        progetto.value = { ...props.data };
        const impianto_id = progetto.value.projects_id ? progetto.value.projects_id : progetto.value.appuntamenti_impianto;
        //console.log(progetto.value);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const showLibretto = ref(true);
        const showInstallazioni = ref(false);
        const showLavorazioni = ref(false);
        const showDocumenti = ref(false);
        const showInterventi = ref(false);
        const showMateriali = ref(false);
        const showImmagini = ref(false);
        const showTickets = ref(false);
        /**
         * ! Switcher to show / hide interventi or ticket
         */
        function setActiveData(type) {
            if (type === "libretto") {
                showLibretto.value = true;
                showInstallazioni.value = false;
                showLavorazioni.value = false;
                showDocumenti.value = false;
                showMateriali.value = false;
                showInterventi.value = false;
                showImmagini.value = false;
                showTickets.value = false;
            } else if (type === "installazioni") {
                showInstallazioni.value = true;
                showLibretto.value = false;
                showLavorazioni.value = false;
                showDocumenti.value = false;
                showMateriali.value = false;
                showTickets.value = false;
                showImmagini.value = false;
                showInterventi.value = false;
            } else if (type === "lavorazioni") {
                showLavorazioni.value = true;
                showInstallazioni.value = false;
                showDocumenti.value = false;
                showLibretto.value = false;
                showMateriali.value = false;
                showTickets.value = false;
                showImmagini.value = false;
                showInterventi.value = false;
            } else if (type === "documenti") {
                showDocumenti.value = true;
                showInterventi.value = false;
                showLibretto.value = false;
                showLavorazioni.value = false;
                showMateriali.value = false;
                showInstallazioni.value = false;
                showImmagini.value = false;
                showTickets.value = false;
            } else if (type === "materiali") {
                showMateriali.value = true;
                showDocumenti.value = false;
                showInterventi.value = false;
                showLibretto.value = false;
                showLavorazioni.value = false;
                showInstallazioni.value = false;
                showImmagini.value = false;
                showTickets.value = false;
            } else if (type === "interventi") {
                showInterventi.value = true;
                showLibretto.value = false;
                showLavorazioni.value = false;
                showDocumenti.value = false;
                showInstallazioni.value = false;
                showMateriali.value = false;
                showImmagini.value = false;
                showTickets.value = false;
            } else if (type === "immagini") {
                showImmagini.value = true;
                showInterventi.value = false;
                showLibretto.value = false;
                showLavorazioni.value = false;
                showDocumenti.value = false;
                showInstallazioni.value = false;
                showMateriali.value = false;
                showTickets.value = false;
            }
        }

        /**
         * ! Remove html tag from ticket description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            } else {
                return "-";
            }
        }

        //Set correct background for ticket status
        const statoTicket = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_open";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_working";
                } else if (statusId == 3) {
                    //ticket attesa risposta
                    className = "badge_waiting_answer";
                } else if (statusId == 4) {
                    //ticket standby
                    className = "badge_standy";
                } else if (statusId == 5) {
                    //tickt chiusi
                    className = "badge_close";
                } else if (statusId == 5) {
                    //tickt cancellati
                    className = "badge_canceled";
                }
                return className;
            };
        });

        //Set correct background for ticket priority
        const prioritaTicket = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_medium";
                } else if (statusId == 3) {
                    //ticket attesa risposta
                    className = "badge_high";
                }
                return className;
            };
        });

        //Set correct background for intervento status
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * ! Badge stato progetto
         */
        const statoProgetto = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 11) {
                    className = "badge_iniziato";
                } else if (statusId == 9) {
                    className = "badge_contrattualizzato";
                } else if (statusId == 10) {
                    className = "badge_da_iniziare";
                } else if (statusId == 12) {
                    className = "badge_lavorazioni_finite";
                } else if (statusId == 13) {
                    className = "badge_cantiere_chiuso";
                }
                return className;
            };
        });

        /**
         * ! Badge priorità progetto
         */
        const prioritaProgetto = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_medium";
                } else if (statusId == 3) {
                    //ticket attesa risposta
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * ! Print correte customer info
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                const trimmedString = cliente.customers_company.length > 40 ? cliente.customers_company.substring(0, 37) + "..." : cliente.customers_company;
                return trimmedString;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                const trimmedString = cliente.customers_name.length > 40 ? cliente.customers_name.substring(0, 37) + "..." : cliente.customers_name;
                return trimmedString;
            }
            const ref = `${cliente.customers_name} ${cliente.customers_last_name}`;
            const trimmedString = ref.length > 40 ? ref.substring(0, 37) + "..." : ref;
            return trimmedString;
        };

        /**
         * ! Get related interventi
         */
        const interventi = ref([]);
        const loadingInterventi = ref(false);

        const loadProjectInterventi = async () => {
            loadingInterventi.value = true;
            try {
                const res = await interventiAPI.getProjectInterventi(impianto_id);
                if (res.status === 0) {
                    interventi.value = res.data;
                } else {
                    openToast("Errore durante la richiesta degli interventi", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli interventi", "toast_danger");
            } finally {
                loadingInterventi.value = false;
            }
        };

        /**
         * ! Get related tickets
         */
        const tickets = ref([]);
        const loadingTickets = ref(false);

        const loadProjectTickets = async () => {
            loadingTickets.value = true;
            try {
                const res = await ticketAPI.getProjectTickets(impianto_id);
                if (res.status === 0) {
                    tickets.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei ticket", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei ticket", "toast_danger");
            } finally {
                loadingTickets.value = false;
            }
        };

        async function goToIntervento(intervento) {
            const modal = await modalController.create({
                component: InterventoDetail,
                componentProps: {
                    data: intervento,
                },
            });
            return modal.present();
        }
        /**
         * ! Open modal to view ticket details
         */
        async function openTicketDetail(ticket) {
            const modal = await modalController.create({
                component: TicketDetail,
                componentProps: {
                    data: ticket,
                },
            });
            return modal.present();
        }

        function showCapoCommessa(impianto) {
            if (impianto.users_first_name && impianto.users_last_name) {
                return `${impianto.users_first_name} ${impianto.users_last_name}`;
            } else {
                return " - ";
            }
        }

        /**
         * ! Get libretto
         */
        const libretto = ref([]);
        const loadLibretto = async () => {
            try {
                const res = await interventiCustomAPI.getLibretto(impianto_id);
                if (res.status === 0) {
                    libretto.value = res.data;
                } else {
                    openToast("Errore durante la richiesta del libretto", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta del libretto", "toast_danger");
            }
        };
        /**
         * ! Get libretto
         */
        const installazioni = ref([]);
        const loadInstallazioni = async () => {
            try {
                const res = await interventiCustomAPI.getInstallazioni(impianto_id);
                if (res.status === 0) {
                    installazioni.value = res.data;
                } else {
                    openToast("Errore durante la richiesta delle installazioni", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle installazioni", "toast_danger");
            }
        };

        /**
         * ! Get lavorazioni
         */
        const lavorazioni = ref([]);
        const loadLavorazioni = async () => {
            try {
                const res = await interventiCustomAPI.getCommessaLavorazioni(impianto_id);
                if (res.status === 0) {
                    lavorazioni.value = res.data;
                } else {
                    openToast("Errore durante la richiesta delle lavorazioni", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle lavorazioni", "toast_danger");
            }
        };
        /**
         * ! Toggle lavorazione completato
         */
        const handleToggleLavorazione = async (lavorazione_id, value) => {
            //console.log(lavorazione_id, value);
            let completato = "1";
            if (value == "1") {
                completato = "0";
            }
            //console.log(completato);
            try {
                const res = await interventiCustomAPI.setLavorazione(lavorazione_id, completato);
                if (res.status === 0 && res.data) {
                    const editedLavorazione = lavorazioni.value.find((lavorazione) => lavorazione.commesse_lavorazioni_id == res.data.commesse_lavorazioni_id);
                    if (editedLavorazione) {
                        editedLavorazione.commesse_lavorazioni_completato = res.data.commesse_lavorazioni_completato;
                    }
                    openToast(res.txt, "toast_success");
                } else {
                    openToast("Errore durante la modifica della lavorazione", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la modifica della lavorazione", "toast_danger");
            }
        };

        /**
         * ! Load documenti
         */
        const documenti = ref([]);
        const loadDocumenti = async () => {
            try {
                const res = await interventiCustomAPI.getDocumentiCommessa(impianto_id);
                if (res.status === 0) {
                    documenti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei documenti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei documenti", "toast_danger");
            }
        };
        function linkDocumento(documento) {
            if (documento && documento.documenti_progetto_file) {
                try {
                    const docData = JSON.parse(documento.documenti_progetto_file)[0];
                    if (docData) {
                        return `https://crm.omniaenergy.eu/uploads/${docData.path_local}`;
                    }
                } catch (e) {
                    return `https://crm.omniaenergy.eu/uploads/${documento.documenti_progetto_file}`;
                }
            }
        }

        /**
         * ! Load materiali
         */
        const materiali = ref([]);
        const loadMateriali = async () => {
            try {
                const res = await interventiCustomAPI.getMaterialeCommessa(impianto_id);
                if (res.status === 0) {
                    materiali.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei materiali", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei materiali", "toast_danger");
            }
        };
        /**
         * ! Load foto
         */
        const immagini = ref([]);
        const loadImmagini = async () => {
            try {
                const res = await interventiCustomAPI.getImmagini(impianto_id);
                if (res.status === 1) {
                    immagini.value = res.images;
                } else {
                    openToast("Errore durante la richiesta delle foto", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle foto", "toast_danger");
            }
        };

        const showModalImmagini = ref(false);
        const selectedImage = ref(null);
        function setSelectedImage(image) {
            showModalImmagini.value = true;
            selectedImage.value = image.interventi_immagini_file;
        }
        function closeModalImmagini() {
            showModalImmagini.value = false;
            selectedImage.value = null;
        }

        /**
         * ! Open modal to create new intervento
         */
        async function openNuovoIntervento() {
            const modal = await modalController.create({
                component: ModalNuovoInterventoFromImpianto,
                componentProps: {
                    data: progetto,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data != undefined) {
                    interventi.value.unshift(detail.data);
                    openToast("Intervento salvato con successo", "toast_success");
                    /**
                     * ? Per la loro logica devo rimandare al dettaglio intervento appena creato
                     * ? in maniera che possano caricare foto e firmare
                     */
                    goToIntervento(detail.data);
                } else {
                    loadInstallazioni();
                }
            });
            return modal.present();
        }

        /**
         * ! Open modal to view intervento details
         */
        async function openInterventoDetail(intervento) {
            const modal = await modalController.create({
                component: InterventoDetail,
                componentProps: {
                    data: intervento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                loadLibretto();
                loadInstallazioni();
                loadLavorazioni();
                loadDocumenti();
                loadMateriali();
                loadImmagini();
            });
            return modal.present();
        }

        onMounted(() => {
            //loadProjectTickets();
            //loadProjectInterventi();
            loadLibretto();
            loadInstallazioni();
            loadLavorazioni();
            loadDocumenti();
            loadMateriali();
            loadImmagini();
        });

        return {
            id,
            loadingInterventi,
            statoTicket,
            dateFormat,
            dateFormatWithMinutes,
            progetto,
            arrowBackOutline,
            closeModal,
            parseHtmlEnteties,
            prioritaTicket,
            openNuovoIntervento,
            interventi,
            statusIntervento,
            openInterventoDetail,
            tickets,
            openTicketDetail,
            loadingTickets,
            //New field
            riferimentoCliente,
            statoProgetto,
            prioritaProgetto,
            showInterventi,
            showTickets,
            setActiveData,
            showCapoCommessa,
            //Campi personalizzati
            libretto,
            showLibretto,
            installazioni,
            showInstallazioni,
            lavorazioni,
            showLavorazioni,
            handleToggleLavorazione,
            showDocumenti,
            documenti,
            linkDocumento,
            showMateriali,
            materiali,
            showImmagini,
            immagini,
            showModalImmagini,
            closeModalImmagini,
            setSelectedImage,
            selectedImage,
            closeOutline,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.richiesta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: 0;
    margin-bottom: 0px;
}
.richiesta .field.field_title {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 6px;
    margin-bottom: 0px;
    border-bottom: none;
}

.richiesta .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.field .title,
.field_allegati .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .project_title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.field_allegati .title,
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_allegati .value,
.field_note .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container,
.tickets_container {
    margin-top: 16px;
}
.interventi_container .interventi_label,
.tickets_container .tickets_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #475569;
}
.intervento,
.ticket {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.intervento .status,
.ticket .status {
    font-size: 14px;
}

.ticket .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 4px;
}

.ticket .date {
    font-size: 12px;
    color: #475569;
}

.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
}

.no_interventi_container {
    width: 100%;
    /*padding: 16px;*/
}

.no_interventi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

.no_tickets_container {
    width: 100%;
    /*padding: 16px;*/
}

.no_tickets {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

/**
  * Info progetto
  */
.stato_progetto .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.stato_progetto .badge_iniziato {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.stato_progetto .badge_contrattualizzato {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.stato_progetto .badge_pause {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.stato_progetto .badge_lavorazioni_finite {
    color: rgb(243 244 246);
    background-color: rgb(31 41 55);
    font-weight: bold;
}
.stato_progetto .badge_cantiere_chiuso {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}

.stato_progetto .badge_da_iniziare {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}

/**
  * Switcher Interventi e Ticket
  */
.data_switcher {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
}
.data_switcher .type {
    /*width: 48%;*/
    min-width: 150px;
    color: #475569;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding: 8px 4px;
    border-radius: 20px;
    text-align: center;
}
.data_switcher .type.active {
    background-color: #086fa3;
    color: #ffffff;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

ion-button {
    --color: #ffffff;
}

.libretto {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.libretto .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 4px;
}

.libretto_text {
    font-size: 12px;
}

.lavorazioni {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lavorazioni .nome {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}

.lavorazioni .toggler {
    font-size: 12px;
}

.documenti {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.documento {
    padding: 8px;
    margin-bottom: 12px;
}
.documento .nome {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}

.documento .link_documento {
    text-decoration: none;
    color: #2e94c8;
}

/* IMMAGINI */
.gallery_container {
    height: 110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    gap: 8px;
}
ion-thumbnail {
    --size: 100px;
    --border-radius: 4px;
}
.fake_images_modal_container {
    position: relative;
}
.fake_modal {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    /*position: absolute;*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1000;
}
.close_modal {
    position: absolute;
    top: 65px;
    right: 16px;
    background: #ffffff;
    font-size: 18px;
    color: #000000;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}
.image_container {
    width: 100%;
    height: 100%;
    padding: 16px;
    /*padding-top: 32px;*/
    padding-top: 100px;
    position: relative;
}

/* Nuovo intervento */
.nuovo_intervento_container {
    padding: 16px 0;
}
.btn_nuovo_intervento {
    background-color: #086fa3;
    color: #ffffff;
    width: 100%;
    padding: 8px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 18px;
}

.no_photo_uploaded {
    margin-top: 4px;
    color: rgb(239 68 68);
}
</style>
