import axios from "axios";

export default {
    async getCapiCantiere() {
        const data = new FormData();
        data.append("where[users_active]", "1");
        data.append("where[users_type]", "12");
        data.append("orderby", "users_last_name");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/users", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
};
