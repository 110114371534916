
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonInput,
    modalController,
    menuController,
    IonButtons,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { openToast } from "@/services/toast";

import apiClienti from "@/custom/services/clienti";

import ClienteDetail from "@/custom/clienti/ClienteDetail.vue";

export default {
    name: "Clienti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const clienti = ref([]);
        const searchQuery = ref("");

        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return clienti.value.filter((cliente) => {
                if (cliente.customers_company) {
                    return (
                        cliente.customers_company
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else {
                    if (cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_last_name + cliente.customers_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_name + cliente.customers_last_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (cliente.customers_name && !cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_city
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (!cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_city
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    }
                }
            });
        });

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.comuni_label ? cliente.comuni_label : "";
            const address = cliente.customers_address ? " - " + cliente.customers_address : "";
            return `${city} ${address}`;
        };

        /**
         * Passing customer objectto ClienteDetail page
         */
        async function openDetailModal(customer) {
            //console.log(customer);
            const modal = await modalController.create({
                component: ClienteDetail,
                componentProps: {
                    data: customer,
                },
            });
            return modal.present();
        }

        /**
         * Get all clienti
         */
        async function loadClienti() {
            loading.value = true;
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    clienti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                clienti.value = [];
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        onMounted(() => {
            //GET CLIENTI DATA
            loadClienti();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            loadClienti,
            clienti,
            searchQuery,
            searchedCustomers,
            riferimentoCliente,
            indirizzoCliente,
            menu,
            openMenu,
        };
    },
};
