<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title color="white">Foto report</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <div v-if="sendingPhotos" class="loading_spinner">
          <ion-text color="dark">
            <h5>Aggiunta delle foto in corso...</h5>
          </ion-text>
          <ion-spinner name="crescent" color="primary"></ion-spinner>
        </div>

        <div v-else>
          <div class="select_images">
            <div class="select_images_button" @click="showActionSheetPhotos()">Carica foto</div>
          </div>
          <!--           <div class="container_help_text">
            Per aggiungere foto clicca sul pulsante blu in basso a destra. Si aprirà una schermata in cui scegliere se scattarne una o caricarla dalla galleria.
          </div> -->
          <div class="container_actions">
            <div @click="closeModal()" class="btn_actions_images">Chiudi</div>
            <div @click="savePhoto()" class="btn_actions_images">Salva</div>
          </div>

          <div class="photos_container">
            <ion-grid>
              <ion-row>
                <ion-col size="6" :key="photo" v-for="photo in userPhoto">
                  <ion-img :src="photo.webviewPath" class="img_photo"></ion-img>
                  <div @click="showActionSheet(photo)" class="img_action">
                    <ion-icon :icon="trash"></ion-icon>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>

          <!--           <ion-fab horizontal="end" vertical="bottom" slot="fixed">
            <ion-fab-button color="primary" @click="showActionSheetPhotos()">
              <ion-icon :icon="camera"></ion-icon>
            </ion-fab-button>
          </ion-fab> -->
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  //IonFab,
  //IonFabButton,
  actionSheetController,
  IonText,
  IonSpinner,
} from "@ionic/vue";
import { camera, trash, images } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "../services/toast";

//import apiInterventi from "../services/interventi";

import { usePhotoGallery, UserPhoto } from "../services/usePhotoGallery";

import imageToBase64 from "image-to-base64/browser";

export default defineComponent({
  name: "ImagesModal",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonIcon,
    //IonFab,
    //IonFabButton,
    IonText,
    IonSpinner,
  },
  setup(props, context) {
    //Camera functionality
    const { userPhoto, takePhoto, deletePhoto, pickPhotos } = usePhotoGallery();
    const sendingPhotos = ref(false);

    //console.log(props.images);

    /**
     * ! Handle close modal by clicking on Chiudi button or after upload foto
     * ! Send back data to visualize photos in Modal Pulizia form
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    async function closeModalOnSubmit(res) {
      if (res) {
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * ! Handle camera and device selection photos
     */
    const showActionSheetPhotos = async () => {
      const actionSheet = await actionSheetController.create({
        header: "Scegli modalità di caricamento",
        buttons: [
          {
            text: "Fotocamera",
            icon: camera,
            handler: () => {
              takePhoto();
            },
          },
          {
            text: "Galleria",
            icon: images,
            handler: () => {
              pickPhotos();
            },
          },
          {
            text: "Annulla",
            icon: close,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };

    /**
     * ! Handle delete single photo
     */
    const showActionSheet = async (photo) => {
      const actionSheet = await actionSheetController.create({
        header: "Foto",
        buttons: [
          {
            text: "Elimina",
            role: "destructive",
            icon: trash,
            handler: () => {
              deletePhoto(photo);
            },
          },
          {
            text: "Annulla",
            icon: close,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };

    // Iterates all photos, convert to base64 and return.
    const conversioneFoto = async (userPhoto) => {
      const requests = userPhoto.map((photo) => {
        return imageToBase64(photo.webviewPath) // Path to the image
          .then((response) => {
            //console.log(response);
            const fileName = photo.filepath;
            //fileName = fileName.substring(0, fileName.length - 5);
            //console.log(str);
            const foto = {
              name: fileName,
              data: response,
            };
            return foto;
          })
          .catch((error) => {
            console.error(error);
          });
      });
      return Promise.all(requests); // Waiting for all the requests to get resolved.
    };

    async function savePhoto() {
      if (userPhoto.value.length === 0) {
        openToast("Non puoi salvare senza aver aggiunto almeno una foto", "toast_danger");
      } else {
        sendingPhotos.value = true; //loading invio foto
        const intervento = props.intervento_id;

        conversioneFoto(userPhoto.value).then((photosB64) => {
          //console.log(photosB64);
          closeModalOnSubmit(photosB64);
        });
      }
    }

    return {
      camera,
      trash,
      closeModal,
      closeModalOnSubmit,
      userPhoto,
      takePhoto,
      showActionSheet,
      savePhoto,
      sendingPhotos,
      pickPhotos,
      showActionSheetPhotos,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
ion-button {
  --color: #ffffff;
}
.page_content {
  width: 100%;
  position: relative;
  min-height: 100%;
  background: #f8fafc;
  padding: 16px;
}

.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img_photo {
  position: relative;
}
.img_action {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  background-color: rgb(254 226 226);
  color: rgb(239 68 68);
  font-size: 14px;
  border-radius: 4px;
  padding: 4px;
}

.select100 {
  max-width: 100% !important;
}

.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
  width: 100% !important;
}

.alert-wrapper {
  width: 90% !important;
}

/* New rules */
.container_actions_page {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.btn_close {
  --background: #dc2626;
  --background-activated: #b91c1c;
  --background-hover: #b91c1c;
  --background-focused: #b91c1c;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 16px;
  width: 100%;
}
.btn_save {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
}

.container_help_text {
  margin-bottom: 16px;
  color: #64748b;
  font-size: 14px;
}

/**
* New field
*/
.select_images_button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  background-color: #086fa3;
  color: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  transition: all 0.15s ease-in;
}

.container_actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.btn_actions_images {
  width: 47%;
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  background-color: rgb(226 232 240);
  color: rgb(148 163 184);
}
</style>
