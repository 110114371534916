import axios from "axios";

export default {
  async getAutomezzi() {
    const data = new FormData();
    data.append("where[automezzi_attivo]", '1');

    const response = await axios.post(`rest/v1/search/automezzi`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data.data;
  },

  async getPrenotazioniAutomezzi(dipendenteID) {
    const data = new FormData();
    //data.append("limit", "50");
    data.append("where[prenotazioni_automezzi_dipendente]", dipendenteID.toString());
    data.append("orderby", "prenotazioni_automezzi_creation_date");
    data.append("orderdir", "desc");

    const response = await axios.post(`rest/v1/search/prenotazioni_automezzi`, data, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response.data.data;
  },

  /**
   * Save new prenotazione
   */
  async savePrenotazione(prenotazione) {
    const response = await axios.post(`rest/v1/create/prenotazioni_automezzi`, prenotazione, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },

  /**
   * Edit prenotazione
   */
  async editPrenotazione(id, prenotazione) {
    const response = await axios.post(`rest/v1/edit/prenotazioni_automezzi/${id}`, prenotazione, {
      headers: {
        Authorization: `${process.env.VUE_APP_TOKEN}`,
      },
    });
    //console.log(response);
    return response;
  },
};
