import { ref, onMounted, watch } from "vue";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraSource, CameraResultType, Photo, CameraDirection } from "@capacitor/camera";

import { isPlatform } from "@ionic/vue";

export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
}

/**
 * Pick gallery images
 * @returns Array with photos from gallery
 */
export function useScontrinoPhoto() {
    const photos = ref<UserPhoto[]>([]);
    const userPhoto = ref<UserPhoto[]>([]);

    const pickPhotos = async () => {
        const { photos } = await Camera.pickImages({
            quality: 100,
            presentationStyle: "popover",
            limit: 1,
        });

        for (let index = 0; index < photos.length; index++) {
            //Use index for unique timestamp for each photo when select multiple photos
            const photo = photos[index];
            const fileName = new Date().getTime() + index + "." + photo.format;
            const savedFileImage = {
                filepath: fileName,
                webviewPath: photo.webPath,
            };
            userPhoto.value = [savedFileImage, ...userPhoto.value];
        }
    };

    /**
     * Take one single photo from camera
     */
    const takePhoto = async () => {
        const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
            quality: 100,
            direction: CameraDirection.Rear,
            saveToGallery: true,
        });

        const fileName = new Date().getTime() + ".jpeg";
        const savedFileImage = {
            filepath: fileName,
            webviewPath: cameraPhoto.webPath,
        };

        userPhoto.value = [savedFileImage, ...userPhoto.value];
        //console.log(photos.value);
    };

    /**
     * Remove photo from the Photos reference data array
     * @param photo images reference to remove
     */
    const deleteSinglePhoto = async (photo: UserPhoto) => {
        userPhoto.value = userPhoto.value.filter((p) => p.filepath !== photo.filepath);
    };

    /**
     * Convert from blob to base64
     * @param blob blob file to convert into base64
     * @returns
     */
    /*     const convertBlobToBase64 = (blob: Blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

    async function convertPhoto(photo) {
        const response = await fetch(photo.webviewPath);
        const blob = await response.blob();
        const base64Data = (await convertBlobToBase64(blob)) as string;

        return base64Data;
    } */

    return {
        userPhoto,
        takePhoto,
        deleteSinglePhoto,
        //convertPhoto,
        pickPhotos,
    };
}
