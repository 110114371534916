<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio intervento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on ticket attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato ticket" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>
                <div class="intervento">
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ setShortCustomer(intervento.customers_company) }}
                        </div>
                    </div>
                    <div class="field field_two_row">
                        <div class="title">Impianto</div>
                        <div class="value">
                            {{ formatImpianto(intervento) }}
                        </div>
                    </div>
                    <div v-if="intervento.interventi_ticket" class="field field_two_row">
                        <div class="title">Ticket</div>
                        <div class="value">
                            {{ intervento.tickets_subject ?? "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(intervento.interventi_data) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Orario</div>
                        <div class="value">
                            {{ intervento.interventi_ora_inizio + " - " + intervento.interventi_ora_fine }}
                        </div>
                    </div>
                    <!-- <div class="field">
                        <div class="title">Ore da fatturare</div>
                        <div class="value">
                            {{ intervento.tickets_reports_billable_hours }}
                        </div>
                    </div> -->
                    <div class="field" v-if="intervento.interventi_tempo_viaggio">
                        <div class="title">Tempo viaggio (min)</div>
                        <div class="value">
                            {{ intervento.interventi_tempo_viaggio ?? "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato lavori</div>
                        <div class="badge" :class="statusIntervento(intervento.interventi_stato_lavori)">
                            {{ intervento.interventi_stato_lavori_value }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Tecnici</div>
                        <div class="tecnici_container">
                            <div v-for="(tecnico, index) in intervento.interventi_tecnici" :key="index" class="value">
                                {{ tecnico }}
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="intervento.interventi_immagini" class="field_allegati">
                        <div class="title">Allegati</div>
                        <div class="allegati_container">
                            <img
                                v-for="(image, index) in JSON.parse(intervento.tickets_reports_attachments)"
                                :key="index"
                                :src="setImageUrl(image)"
                                alt=""
                                @click="openImgDetail(image)"
                            />
                        </div>
                    </div> -->
                    <div class="field_note description">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            {{ parseHtmlEnteties(intervento.interventi_descrizione) }}
                        </div>
                    </div>
                    <!-- <div v-if="intervento.interventi_materiale_utilizzato" class="field_note description">
                        <div class="title">Materiali utilizzati</div>
                        <div class="value">
                            {{ parseHtmlEnteties(intervento.interventi_materiale_utilizzato) }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title rapportino_container">
                            <a :href="scaricaRapportino(intervento.interventi_id)" @click="fakeDownload()" class="link_rapportino" download
                                >Scarica PDF intervento</a
                            >
                        </div>
                    </div> -->
                </div>

                <div class="nuovo_intervento">
                    <div class="intervento_button" @click="modalPhoto()">
                        Aggiungi foto
                    </div>
                </div>

                <div class="btn_firme_container">
                    <div class="btn_firma">
                        <button class="firma_button" :disabled="customerSigned" @click="openSignature('cliente')">
                            Firma cliente
                        </button>
                    </div>
                    <div class="btn_firma">
                        <button class="firma_button" :disabled="operatorSigned" @click="openSignature('tecnico')">
                            Firma operatore
                        </button>
                    </div>
                </div>

                <div v-if="customerEmail" class="email_container">
                    <button class="email_button" :disabled="sendCustomerMail" @click="sendEmail()">Invia email cliente</button>
                </div>

                <div v-if="!completedIntervento" class="nuovo_intervento">
                    <div class="intervento_button" @click="saveIntervento()">
                        Salva intervento
                    </div>
                </div>

                <!-- <div v-if="intervento.tickets_reports_ticket_id" ref="ticketDom" class="intervento" style="margin-top: 32px;">
                    <div class="title_card" @click="showTicketDetail = !showTicketDetail">Dettaglio ticket #{{ intervento.tickets_reports_ticket_id }}</div>
                    <div v-if="showTicketDetail">
                        <div class="field">
                            <div class="title">Titolo</div>
                            <div class="value">
                                {{ intervento.tickets_subject ? intervento.tickets_subject : "-" }}
                            </div>
                        </div>
                        <div class="field">
                            <div class="title">Data apertura</div>
                            <div class="value">
                                {{ intervento.tickets_creation_date ? dateFormat(intervento.tickets_creation_date) : "-" }}
                            </div>
                        </div>
                        <div v-if="intervento.tickets_attachments" class="field_allegati">
                            <div class="title">Allegati</div>
                            <div class="allegati_container">
                                <img
                                    v-for="(image, index) in JSON.parse(intervento.tickets_attachments)"
                                    :key="index"
                                    :src="setImageUrl(image)"
                                    alt=""
                                    @click="openImgDetail(image)"
                                />
                            </div>
                        </div>
                        <div class="field_note">
                            <div class="title">Descrizione</div>
                            <div class="value">
                                {{ parseHtmlEnteties(intervento.tickets_message) }}
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    onIonViewWillEnter,
    modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";
import autoAnimate from "@formkit/auto-animate";

import { dateFormat } from "@/services/utils";

import { openToast } from "@/services/toast";
import interventiCustomAPI from "@/custom/services/interventi";

import TicketDetail from "@/custom/ticket/TicketDetail.vue";
import ModalFirmaIntervento from "@/custom/interventi/ModalFirmaIntervento";
import ImagesModal from "@/custom/foto/ImagesModal";

export default defineComponent({
    name: "InterventoDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //intervento_id by route params
        const router = useRouter();
        const intervento = ref([]);

        intervento.value = { ...props.data };
        //console.log(intervento.value);

        const ticketDom = ref();
        const showTicketDetail = ref(false);

        // true se intervento ha foto (btn nasconsto), false altrimenti e mostro btn con messaggio errore
        const completedIntervento = ref(false);
        const hasPhotos = ref(false);

        //Gestione button firme cliente/operatore e invio mail
        const customerSigned = ref(false);
        const operatorSigned = ref(false);
        const customerEmail = ref(false);
        const sendCustomerMail = ref(false);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Controllo presenza immagini su intervento per completato o non completato
         */
        async function checkInterventoCompletato() {
            /**
             * Se intervento ha almeno una foto caricata e interventi_completato = false faccio salvare (edit su intervento_completate = true) e si vedrà nel gestionale
             * Se intervento non ha foto mostro errore dicendo che devono caricare almeno una foto per salvare l'intevento
             * Se intervento ha almeno una foto caricata e interventi_completato = true nascono button per salvare
             */

            try {
                const response = await interventiCustomAPI.getInterventoImages(intervento.value.interventi_id);

                if (response.data.status === 0) {
                    //Foto collegato all'intervento trovate
                    if (response.data.data.length != 0) {
                        if (intervento.value.interventi_completato == "1") {
                            //ho foto e intervento completato, nascondo pulsante
                            completedIntervento.value = true;
                            hasPhotos.value = true;
                            //console.log("intervento con foto e completo = ", completedIntervento.value);
                        } else {
                            completedIntervento.value = false;
                            hasPhotos.value = true;
                            //console.log("intervento con foto e completo = ", completedIntervento.value);
                        }
                    }
                }
            } catch (error) {
                //error.value = true;
                console.error(error);
                openToast("Errore durante il controllo dell'intervento", "danger");
            }
        }

        /**
         * Open modal to add images in intervento
         */
        async function modalPhoto() {
            const modal = await modalController.create({
                component: ImagesModal, //Component to render inside ionic modal
                componentProps: {
                    intervento_id: intervento.value.interventi_id,
                },
            });

            modal.onDidDismiss().then((data) => {
                //console.log(data.data);
                if (data.data == true) {
                    //console.log(data);
                    openToast("Foto caricate con successo", "toast_success");
                    interventiCustomAPI
                        .getInterventoImages(intervento.value.interventi_id)
                        .then((response) => {
                            //console.log(response);
                            if (response.data.status === 0) {
                                //Foto collegate all'intervento trovate
                                if (response.data.data.length != 0) {
                                    //commento perchè in teoria sto caricando le foto per l'intervento che logicamente NON è completato
                                    hasPhotos.value = true;
                                    /* if (intervento.value.interventi_completato == "1") {
                                        //ho foto e intervento completato, nascondo pulsante
                                        completedIntervento.value = true;
                                        hasPhotos.value = true;
                                    } else {
                                        completedIntervento.value = false;
                                        hasPhotos.value = false;
                                    } */
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            openToast("Errore durante il controllo delle foto dell'intervento", "toast_danger");
                        });
                    /*try {
                        const response = interventiCustomAPI.getInterventoImages(intervento.value.interventi_id);

                        console.log(intervento);
                        console.log(response);
                        console.log(completedIntervento.value);
                        console.log(hasPhotos.value);

                        if (response.status === 0) {
                            //Foto collegate all'intervento trovate
                            if (response.data.length != 0) {
                                if (intervento.value.interventi_completato == "1") {
                                    //ho foto e intervento completato, nascondo pulsante
                                    completedIntervento.value = true;
                                    hasPhotos.value = true;
                                } else {
                                    completedIntervento.value = false;
                                    hasPhotos.value = false;
                                }
                            }
                        }
                        console.log(completedIntervento.value);
                        console.log(hasPhotos.value);
                    } catch (error) {
                        console.error(error);
                        openToast("Errore durante il controllo delle foto dell'intervento", "toast_danger");
                    }*/
                }
            });
            return modal.present();
        }

        /**
         * ! Controllo firme per disabilitare button
         * ! Se cliente ha email mostro button
         * ! Disabilito button email cliente se è già stata inviata
         */
        function checkFirme() {
            //console.log(intervento);
            if (intervento.value.interventi_firma_cliente_b64) {
                customerSigned.value = true;
            }
            if (intervento.value.interventi_firma_operatore_b64) {
                operatorSigned.value = true;
            }
            if (intervento.value.customers_email) {
                customerEmail.value = true;
            }
            if (intervento.value.interventi_invia_mail === "0") {
                sendCustomerMail.value = false;
            } else {
                sendCustomerMail.value = true;
            }
        }

        /**
         * ! Open modal to sign
         */
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    intervento_id: intervento.value.interventi_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, disabilito button corrispondente
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            operatorSigned.value = true;
                            /* intervento.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            } */
                        } else {
                            customerSigned.value = true;
                            /* intervento.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            } */
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da intervento
                    /* if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            intervento.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            intervento.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    } */
                }
            });
            return modal.present();
        }

        /**
         * ! Se intervento ha almeno una foto caricata e interventi_completato = false faccio salvare (edit su intervento_completate = true) e si vedrà nel gestionale
         * ! Se intervento non ha foto mostro errore dicendo che devono caricare almeno una foto per salvare l'intevento
         * ! Se intervento ha almeno una foto caricata e interventi_completato = true nascono button per salvare
         */
        async function saveIntervento() {
            if (hasPhotos.value) {
                if (intervento.value.interventi_completato == null || intervento.value.interventi_completato == "0") {
                    try {
                        const response = await interventiCustomAPI.setInterventoCompleto(intervento.value.interventi_id);

                        intervento.value.interventi_completato = response.data.data.interventi_completato;
                        completedIntervento.value = true;
                        openToast("Intervento salvato con successo", "toast_success");
                    } catch (error) {
                        console.error(error);
                        openToast("Errore durante il salvataggio dell'intervento", "toast_danger");
                    }
                }
            } else {
                openToast("Non puoi salvare l'intervento senza aver prima caricato almeno una foto", "toast_danger");
            }
        }

        async function sendEmail() {
            /**
             * Send email to customer with PDF created with intervento details
             */
            const idIntervento = intervento.value.interventi_id;
            const data = new FormData();
            data.append("interventi_invia_mail", "1");

            try {
                const response = await interventiCustomAPI.sendCustomerMail(data, idIntervento);

                sendCustomerMail.value = true;
                intervento.value.interventi_invia_mail = "1";
                openToast("Verrà inviata una email al cliente con i dettagli dell'intervento", "toast_success");
            } catch (error) {
                openToast("Errore durante l'invio della email'", "toast_danger");
            }
        }

        /**
         * ! Limit customers to 35 charactes
         */
        function setShortCustomer(customer) {
            if (!customer) {
                return "-";
            } else {
                const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
                return trimmedString;
            }
        }

        function formatImpianto(intervento) {
            if (intervento.projects_codice) {
                return `${intervento.projects_codice} - ${intervento.customers_address}`;
            } else {
                return `${intervento.customers_address}`;
            }
        }

        /**
         * ! Estrae dati del ticket per apertura dettaglio
         */
        /* async function openTicketDetail(intervento) {
            if (intervento.tickets_reports_ticket_id) {
                console.log(intervento.tickets_reports_ticket_id);
                const modal = await modalController.create({
                    component: TicketDetail,
                    componentProps: {
                        data: intervento,
                        ticket_id: intervento.tickets_reports_ticket_id,
                    },
                });
                modal.onDidDismiss().then((detail) => {
                });
                return modal.present();
            }
        } */

        /**
         * Remove html tag from ticket description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            } else {
                return "-";
            }
        }

        //Set correct background for intervento status
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == "1") {
                    //lavoro completato
                    className = "badge_low";
                } else {
                    //lavoro non completato
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * Open fake modal to view selected ticket attachment
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);

        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        function scaricaRapportino(interventoId) {
            return `${process.env.VUE_APP_BASE_URL}tickets-report/main/getreportpdf/${interventoId}`;
        }

        function fakeDownload() {
            openToast("Download in corso...", "toast_info");
        }

        onIonViewWillEnter(() => {
            checkFirme();
            checkInterventoCompletato();
        });

        onMounted(() => {
            /* if (intervento.value && intervento.value.tickets_subject) {
                autoAnimate(ticketDom.value);
            }*/
            checkFirme();
            checkInterventoCompletato();
        });

        return {
            id,
            dateFormat,
            intervento,
            arrowBackOutline,
            closeModal,
            parseHtmlEnteties,
            setShortCustomer,
            statusIntervento,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setImageUrl,
            //Open PDF rapportino
            scaricaRapportino,
            fakeDownload,
            //Ticket detail
            //openTicketDetail,
            ticketDom,
            showTicketDetail,
            //NUOVI CAMPI
            formatImpianto,
            completedIntervento,
            hasPhotos,
            customerSigned,
            operatorSigned,
            openSignature,
            saveIntervento,
            modalPhoto,
            customerEmail,
            sendCustomerMail,
            sendEmail,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.intervento .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.intervento .field.field_two_row {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.intervento .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.intervento .field_note.description {
    border-bottom: 1px solid #e2e8f0;
}
.intervento .field_note.description:last-of-type {
    border-bottom: none;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_close,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_answer {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.intervento .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}
/** Allegati  */
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}
.intervento .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.field_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_allegati .title {
    margin-bottom: 8px;
}
.field_allegati .value {
    font-size: 14px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

.nuovo_intervento,
.email_container {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Button firme */
.btn_firme_container {
    display: flex;
    gap: 20px;
}
.btn_firme_container .btn_firma {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.btn_firma .firma_button,
.email_button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.btn_firma .firma_button:disabled,
.btn_firma .firma_button:disabled,
.email_container .email_button:disabled {
    opacity: 0.6;
}

.tecnici_container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    flex-direction: column;
}
</style>
