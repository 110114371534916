import axios from "axios";

export default {
    /**
     * * Interventi tecnico loggato
     */
    async getInterventi(userID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("where[]", `interventi_id IN (SELECT interventi_id FROM interventi_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("orderby", "interventi_data");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/interventi", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        localStorage.setItem("interventi", JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    /**
     * * Interventi del ticket selezionato
     */
    async getInterventiTicket(id: string) {
        const data = new FormData();
        data.append("where[tickets_reports_ticket_id]", id.toString());
        data.append("orderby", "tickets_reports_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/tickets_reports", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data.data);
        return response.data.data;
    },

    /*
     * interventi CON ticket e CON fatturazione --> AGGIORNARE VISUALIZZAZIONE LIBRETTO IN DETT. IMPIANTO
     */
    async getLibretto(id: string) {
        const data = new FormData();
        data.append("where[interventi_impianto]", id);
        data.append(
            "where[]",
            `interventi_ticket IN (SELECT tickets_id FROM tickets WHERE tickets_project_id = ${id} AND (tickets_fatturazione IS NOT NULL AND tickets_fatturazione <> ''))`
        );

        const response = await axios.post(`rest/v1/search/interventi`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        return response.data;
    },
    /*
     * nelle installazioni andranno gli interventi creati SENZA ticket
     */
    async getInstallazioni(id: string) {
        const data = new FormData();
        //data.append("where[interventi_impianto]", id);
        //data.append("where[]", `interventi_ticket NOT IN (SELECT tickets_id FROM tickets WHERE tickets_project_id = ${id})`);
        data.append("where[]", `interventi_impianto = '${id}' AND (interventi_ticket IS NULL OR interventi_ticket = '')`);
        data.append("orderby", "interventi_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/interventi", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log("installazioni: ", response.data);
        return response.data;
    },

    /**
     * ! Lavorazioni
     */
    async getCommessaLavorazioni(id: string) {
        const data = new FormData();
        data.append("projects_id", id);
        const response = await axios.post(`https://crm.omniaenergy.eu/custom/app/getLavorazioni`, data);

        return response.data;
    },
    async setLavorazione(id: string, value: string) {
        const data = new FormData();
        data.append("commesse_lavorazioni_id", id);
        data.append("commesse_lavorazioni_completato", value);
        const response = await axios.post(`https://crm.omniaenergy.eu/custom/app/setLavorazione`, data);

        return response.data;
    },

    /**
     * ! Documenti commessa
     */
    async getDocumentiCommessa(id: string) {
        const data = new FormData();
        data.append("where[documenti_progetto_progetto]", id);
        data.append("orderby", "documenti_progetto_file");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/documenti_progetto", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    /**
     * ! Materiali commessa
     */
    async getMaterialeCommessa(id: string) {
        const data = new FormData();
        data.append("where[projects_billing_items_project]", id);

        const response = await axios.post("rest/v1/search/projects_billing_items", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data);
        return response.data;
    },
    /**
     * ! Immagini commessa
     */
    async getImmagini(id: string) {
        const data = new FormData();
        data.append("project_id", id);
        const response = await axios.post(`https://crm.omniaenergy.eu/custom/app/getProjectImages`, data);

        return response.data;
    },

    /**
     * ! Stato lavori
     */
    async getStatoLavori() {
        const response = await axios.get("rest/v1/search/interventi_stato_lavori", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    /**
     * ! Save intervento
     */
    async saveIntervento(intervento) {
        const response = await axios.post(`rest/v1/create/interventi`, intervento, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! SALVATAGGIO FIRME OPERATORE / CLIENTE
     */
    async saveOperatorSign(firma: string, idIntervento: string) {
        const data = new FormData();
        data.append("interventi_firma_operatore_b64", firma);

        const response = await axios.post(`rest/v1/edit/interventi/${idIntervento}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async saveCustomerSign(firma: string, idIntervento: string) {
        const data = new FormData();
        data.append("interventi_firma_cliente_b64", firma);

        const response = await axios.post(`rest/v1/edit/interventi/${idIntervento}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! CAMBIA STATO INTERVENTO METTENDOLO COMPLETATO (SE SONO STATE CARICATE LE FOTO E FIRMATO)
     */
    async setInterventoCompleto(idIntervento: string) {
        const data = new FormData();
        data.append("interventi_completato", "1");

        const response = await axios.post(`rest/v1/edit/interventi/${idIntervento}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! CONTROLLO SE INTERVENTO è DA CONSIDERARE COMPLETO O MENO
     */
    async getInterventoImages(intervento) {
        const data = new FormData();
        data.append("intervento_id", intervento);

        const response = await axios.post(`https://crm.omniaenergy.eu/custom/app/getInterventoImages`, data);
        //console.log(response);
        return response;
    },

    /**
     * ! SALVATAGGIO FOTO INTERVENTO
     */
    async savePhotos(photos, intervento) {
        const data = new FormData();
        data.append("intervento_id", intervento);
        data.append("photos", JSON.stringify(photos));

        const response = await axios.post(`https://crm.omniaenergy.eu/custom/app/saveImage`, data);
        //console.log(response);
        return response;
    },

    /**
     * ! INVIO MAIL CLIENTE
     */
    async sendCustomerMail(email_status, idIntervento) {
        const response = await axios.post(`rest/v1/edit/interventi/${idIntervento}`, email_status, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
};
