import { ref } from "vue";
import { Capacitor } from "@capacitor/core";
import { Camera, CameraSource, CameraResultType, Photo, CameraDirection } from "@capacitor/camera";

export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
}

export function usePhotoGallery() {
    // const userPhoto = ref<UserPhoto[]>([]);
    const userPhoto = ref([]);
    //const galleryPhotos = ref<UserPhoto[]>([]);

    //CHOOSE PHOTOS FROM GALLERY
    const pickPhotos = async (selectedCategory) => {
        const { photos } = await Camera.pickImages({
            quality: 100,
            presentationStyle: "popover",
            limit: 4,
        });

        for (let index = 0; index < photos.length; index++) {
            //Use index for unique timestamp for each photo when select multiple photos
            const photo = photos[index];
            const fileName = new Date().getTime() + index + "." + photo.format;
            const savedFileImage = {
                category: selectedCategory, //selected category
                filepath: fileName,
                webviewPath: photo.webPath,
            };
            userPhoto.value = [savedFileImage, ...userPhoto.value];
        }
        //console.log(userPhoto.value);
        //return galleryPhotos;
    };

    //TAKE SINGLE PHOTO WITH CAMERA
    const takePhoto = async (selectedCategory) => {
        const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
            direction: CameraDirection.Rear,
            saveToGallery: true,
        });

        const fileName = new Date().getTime() + ".jpeg";
        const savedFileImage = {
            category: selectedCategory, //selected category
            filepath: fileName,
            webviewPath: cameraPhoto.webPath,
        };

        userPhoto.value = [savedFileImage, ...userPhoto.value];
        //console.log(userPhoto.value);
    };

    // Remove this photo from the Photos reference data array
    const deletePhoto = async (photo: UserPhoto) => {
        userPhoto.value = userPhoto.value.filter((p) => p.filepath !== photo.filepath);
    };

    // Convert photos in base 64 format
    const convertBlobToBase64 = (blob: Blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });

    async function convertPhoto(photo) {
        const response = await fetch(photo.webviewPath);
        const blob = await response.blob();
        const base64Data = (await convertBlobToBase64(blob)) as string;

        return base64Data;
    }

    return {
        userPhoto,
        takePhoto,
        deletePhoto,
        convertPhoto,
        pickPhotos,
        //galleryPhotos
    };
}
